var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.live
    ? _c(
        "div",
        {
          staticClass: "apertura-inside",
          attrs: {
            "data-epica-module-name": "Apertura En Vivo",
            "data-mrf-recirculation": "Apertura En Vivo",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "content-videoInside" }, [
            _c("div", { staticClass: "wrap-videoInside" }, [
              _c("div", {
                staticClass: "embed-responsive embed-responsive-16by9",
                domProps: { innerHTML: _vm._s(_vm.live.embedVideo) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "pd" }, [
                _c("h2", [
                  _c("a", {
                    staticClass: "insideSect",
                    domProps: { innerHTML: _vm._s(_vm.live.title) },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.live.feedTitle && _vm.live.feed && _vm.live.feed.length
              ? _c("div", { staticClass: "feed pd" }, [
                  _c("h2", {
                    staticClass: "title-feed-inside",
                    domProps: { innerHTML: _vm._s(_vm.live.feedTitle) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "wrap-feed" }, [
                    _c(
                      "div",
                      { staticClass: "feed-inside" },
                      _vm._l(_vm.live.feed, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "list-feed" },
                          [
                            item.image
                              ? _c("img", {
                                  attrs: {
                                    src: item.image.url,
                                    alt: item.image.alt,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "hour",
                              domProps: { innerHTML: _vm._s(item.time) },
                            }),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "date",
                              domProps: { innerHTML: _vm._s(item.date) },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              domProps: { innerHTML: _vm._s(item.content) },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row en-vivo" }, [
      _c("div", { staticClass: "grid-x" }, [
        _c("div", { staticClass: "icon-live" }, [
          _c("img", {
            attrs: {
              src: "https://s.lalr.co/lr/images/icon-live-gif.gif",
              alt: "live",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("EN VIVO")]),
          _vm._v(" "),
          _c("div", { staticClass: "line" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }