<template>
  <div
    class="MyTopics"
    data-epica-module-name="Mis Noticias"
    data-mrf-recirculation="Mis Noticias"
    v-if="hasLoaded && user"
  >
    <div class="heading-title grid-x align-bottom pd">
      <h3 class="titleModule">
        <a
          href="https://mas.larepublica.co/noticias"
          target="_blank"
          rel="noopener"
          >MIS NOTICIAS</a
        >
      </h3>
      <div class="auto lineTitle"></div>
    </div>
    <div class="notTopics mytopicSect" v-if="!news || !news.length">
      <div class="text pd">
        <span>Usted no ha seleccionado ningún tema de su interés</span>
        <p>
          Lo invitamos a que seleccione los temas de su preferencia para acceder
          directamente a las noticias de su interés.
        </p>
      </div>
      <div class="pd">
        <ul class="stepsTopics">
          <li>
            <strong>Paso 1: </strong>Acceda a
            <a
              href="https://mas.larepublica.co/noticias"
              target="_blank"
              rel="noopener"
              >mas.larepublica.co</a
            >
          </li>
          <li><strong>Paso 2: </strong>Ingrese a <b>"temas de interés"</b>.</li>
          <li>
            <strong>Paso 3: </strong>Busque y seleccione los temas de su
            preferencia.
          </li>
          <li>
            <strong>Paso 4: </strong>Disfrute de las noticas de su interés.
          </li>
        </ul>
        <div class="cell mytopicSect">
          <div class="buttonModule">
            <a
              href="https://mas.larepublica.co/noticias/editar-top-20"
              target="_blank"
              rel="noopener"
              class="btn"
              >ADMINISTRE SUS TEMAS</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="notTopics oneTopics" v-else-if="news.length < 5">
      <div class="row">
        <div class="col">
          <div class="contentText">
            <v-img-title :post="news[0]" />
          </div>
        </div>
        <div class="col columns pd">
          <p class="invite">
            Lo invitamos a que seleccione los temas de su preferencia para
            acceder directamente a las noticias de su interés.
          </p>
          <ul class="stepsTopics">
            <li>
              <strong>Paso 1: </strong>Acceda a
              <a
                href="https://mas.larepublica.co/noticias"
                target="_blank"
                rel="noopener"
                >mas.larepublica.co</a
              >
            </li>
            <li>
              <strong>Paso 2: </strong>Ingrese a <b>"temas de interés".</b>
            </li>
            <li>
              <strong>Paso 3: </strong>Busque y seleccione los temas de su
              preferencia.
            </li>
            <li>
              <strong>Paso 4: </strong>Disfrute de las noticas de su interés.
            </li>
          </ul>
          <div class="cell mytopicSect">
            <div class="buttonModule">
              <a
                href="https://mas.larepublica.co/noticias/editar-top-20"
                target="_blank"
                rel="noopener"
                class="btn"
                >ADMINISTRE SUS TEMAS</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fiveTopics" v-else-if="news.length < 8">
      <div class="col">
        <i-img-title :post="news[0]" />
      </div>
      <div class="col">
        <h-img-title v-for="p in news.slice(1, 3)" :key="p.id" :post="p" />
      </div>
      <div class="col">
        <h-img-title v-for="p in news.slice(3, 5)" :key="p.id" :post="p" />
      </div>
      <div class="pd">
        <div class="cell mytopicSect">
          <div class="buttonModule">
            <a
              href="https://mas.larepublica.co/noticias"
              target="_blank"
              rel="noopener"
              class="btn"
              >MIS NOTICIAS</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="allTopics" v-else>
      <div class="col">
        <i-img-title :post="news[0]" />
      </div>
      <div class="col col-4">
        <h-img-title v-for="p in news.slice(1, 4)" :key="p.id" :post="p" />
      </div>
      <div class="col col-3">
        <v-title v-for="p in news.slice(4, 9)" :key="p.id" :post="p" />
      </div>
      <div class="pd">
        <div class="cell mytopicSect">
          <div class="buttonModule">
            <a
              href="https://mas.larepublica.co/noticias"
              target="_blank"
              rel="noopener"
              class="btn"
              >MIS NOTICIAS</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../../../../../lr/js/vue/store";
import VTitle from "../../post-components/v-title/VTitle.vue";
import VImgTitle from "../../post-components/v-img-title/VImgTitle.vue";
import HImgTitle from "../../post-components/h-img-title/HImgTitle.vue";
import IImgTitle from "../../post-components/i-img-title/IImgTitle.vue";
export default {
  components: { VTitle, VImgTitle, HImgTitle, IImgTitle },
  store,
  updated() {
    document.dispatchEvent(window.lrLoad);
  },
  computed: {
    ...mapGetters("auth", {
      news: "news",
      user: "user",
      hasLoaded: "hasLoaded",
    }),
  },
};
</script>
