var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLoaded && _vm.user
    ? _c(
        "div",
        {
          staticClass: "MyTopics",
          attrs: {
            "data-epica-module-name": "Mis Noticias",
            "data-mrf-recirculation": "Mis Noticias",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          !_vm.news || !_vm.news.length
            ? _c("div", { staticClass: "notTopics mytopicSect" }, [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
              ])
            : _vm.news.length < 5
            ? _c("div", { staticClass: "notTopics oneTopics" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "contentText" },
                      [_c("v-img-title", { attrs: { post: _vm.news[0] } })],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                ]),
              ])
            : _vm.news.length < 8
            ? _c("div", { staticClass: "fiveTopics" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [_c("i-img-title", { attrs: { post: _vm.news[0] } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  _vm._l(_vm.news.slice(1, 3), function (p) {
                    return _c("h-img-title", { key: p.id, attrs: { post: p } })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  _vm._l(_vm.news.slice(3, 5), function (p) {
                    return _c("h-img-title", { key: p.id, attrs: { post: p } })
                  }),
                  1
                ),
                _vm._v(" "),
                _vm._m(4),
              ])
            : _c("div", { staticClass: "allTopics" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [_c("i-img-title", { attrs: { post: _vm.news[0] } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col col-4" },
                  _vm._l(_vm.news.slice(1, 4), function (p) {
                    return _c("h-img-title", { key: p.id, attrs: { post: p } })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col col-3" },
                  _vm._l(_vm.news.slice(4, 9), function (p) {
                    return _c("v-title", { key: p.id, attrs: { post: p } })
                  }),
                  1
                ),
                _vm._v(" "),
                _vm._m(5),
              ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading-title grid-x align-bottom pd" }, [
      _c("h3", { staticClass: "titleModule" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://mas.larepublica.co/noticias",
              target: "_blank",
              rel: "noopener",
            },
          },
          [_vm._v("MIS NOTICIAS")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "auto lineTitle" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text pd" }, [
      _c("span", [
        _vm._v("Usted no ha seleccionado ningún tema de su interés"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n        Lo invitamos a que seleccione los temas de su preferencia para acceder\n        directamente a las noticias de su interés.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pd" }, [
      _c("ul", { staticClass: "stepsTopics" }, [
        _c("li", [
          _c("strong", [_vm._v("Paso 1: ")]),
          _vm._v("Acceda a\n          "),
          _c(
            "a",
            {
              attrs: {
                href: "https://mas.larepublica.co/noticias",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("mas.larepublica.co")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Paso 2: ")]),
          _vm._v("Ingrese a "),
          _c("b", [_vm._v('"temas de interés"')]),
          _vm._v("."),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Paso 3: ")]),
          _vm._v(
            "Busque y seleccione los temas de su\n          preferencia.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Paso 4: ")]),
          _vm._v("Disfrute de las noticas de su interés.\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cell mytopicSect" }, [
        _c("div", { staticClass: "buttonModule" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: {
                href: "https://mas.larepublica.co/noticias/editar-top-20",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("ADMINISTRE SUS TEMAS")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col columns pd" }, [
      _c("p", { staticClass: "invite" }, [
        _vm._v(
          "\n          Lo invitamos a que seleccione los temas de su preferencia para\n          acceder directamente a las noticias de su interés.\n        "
        ),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "stepsTopics" }, [
        _c("li", [
          _c("strong", [_vm._v("Paso 1: ")]),
          _vm._v("Acceda a\n            "),
          _c(
            "a",
            {
              attrs: {
                href: "https://mas.larepublica.co/noticias",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("mas.larepublica.co")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Paso 2: ")]),
          _vm._v("Ingrese a "),
          _c("b", [_vm._v('"temas de interés".')]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Paso 3: ")]),
          _vm._v(
            "Busque y seleccione los temas de su\n            preferencia.\n          "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Paso 4: ")]),
          _vm._v("Disfrute de las noticas de su interés.\n          "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cell mytopicSect" }, [
        _c("div", { staticClass: "buttonModule" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: {
                href: "https://mas.larepublica.co/noticias/editar-top-20",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("ADMINISTRE SUS TEMAS")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pd" }, [
      _c("div", { staticClass: "cell mytopicSect" }, [
        _c("div", { staticClass: "buttonModule" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: {
                href: "https://mas.larepublica.co/noticias",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("MIS NOTICIAS")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pd" }, [
      _c("div", { staticClass: "cell mytopicSect" }, [
        _c("div", { staticClass: "buttonModule" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: {
                href: "https://mas.larepublica.co/noticias",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("MIS NOTICIAS")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }