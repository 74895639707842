var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.live
    ? _c("div", { staticClass: "live-70-years mg" }, [
        _c("div", {
          staticClass: "embed-responsive embed-responsive-16by9",
          domProps: { innerHTML: _vm._s(_vm.live.embedVideo) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "feed-live" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("h2", { domProps: { innerHTML: _vm._s(_vm.live.title) } }),
          _vm._v(" "),
          _vm.live.feedTitle && _vm.live.feed && _vm.live.feed.length
            ? _c("div", { staticClass: "feeds" }, [
                _c(
                  "div",
                  _vm._l(_vm.live.feed, function (item, index) {
                    return _c("div", { key: index }, [
                      _c("span", {
                        staticClass: "hour",
                        domProps: { innerHTML: _vm._s(item.time) },
                      }),
                      _vm._v(" "),
                      item.image
                        ? _c("img", {
                            attrs: { src: item.image.url, alt: item.image.alt },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", {
                        domProps: { innerHTML: _vm._s(item.content) },
                      }),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "live-signal" }, [
      _c("div", { staticClass: "special-btn" }, [_vm._v("En Vivo")]),
      _vm._v(" "),
      _c("div", { staticClass: "concentric-waves" }, [
        _c("div", { staticClass: "point" }),
        _vm._v(" "),
        _c("div", { staticClass: "spinner-item" }),
        _vm._v(" "),
        _c("div", { staticClass: "spinner-item spinner-item-2" }),
        _vm._v(" "),
        _c("div", { staticClass: "spinner-item spinner-item-3" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }