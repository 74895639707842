var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapInfoDolar",
      attrs: { "data-mrf-recirculation": "Dolar Spot" },
    },
    [
      _vm.header && _vm.header.print
        ? _c("div", [
            _c("a", { attrs: { href: _vm.header.quoteUrl } }, [
              _c("h3", { staticClass: "nameIndicator" }, [
                _vm._v("DOLAR SPOT"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "content-price",
                class: _vm.header.print.upDownClass,
              },
              [
                _c("span", {
                  staticClass: "price",
                  domProps: {
                    innerHTML: _vm._s(_vm.header.print.closingPrice),
                  },
                }),
                _vm._v(" "),
                _c("i", { class: "icon-" + _vm.header.print.upDownClass }),
                _vm._v(" "),
                _c("div", { staticClass: "grid-x infoDetail" }, [
                  _c("span", {
                    staticClass: "varAbs",
                    class: _vm.header.print.upDownClass,
                    domProps: {
                      innerHTML: _vm._s(_vm.header.print.absoluteVariation),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "varPor",
                    domProps: {
                      innerHTML: _vm._s(_vm.header.print.percentageVariation),
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "grid-x pricesIndicator" }, [
              _c("div", [
                _c("h4", { staticClass: "labeItemprice" }, [
                  _vm._v("PRECIO DE APERTURA"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: {
                    innerHTML: _vm._s(_vm.header.print.openingPrice),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("h4", { staticClass: "labeItemprice" }, [
                  _vm._v("PRECIO PROMEDIO"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: { innerHTML: _vm._s(_vm.header.print.avgPrice) },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("h4", { staticClass: "labeItemprice" }, [
                  _vm._v("PRECIO MÁXIMO"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: { innerHTML: _vm._s(_vm.header.print.maxPrice) },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("h4", { staticClass: "labeItemprice" }, [
                  _vm._v("PRECIO MÍNIMO"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: { innerHTML: _vm._s(_vm.header.print.minPrice) },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cell noTransacciones" }, [
                _c("h4", { staticClass: "labeItemprice" }, [
                  _vm._v("No. DE TRANSACCIONES"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: {
                    innerHTML: _vm._s(_vm.header.print.transactionCount),
                  },
                }),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "dolar-spot-graph" }, [
        _c(
          "div",
          { staticClass: "graph" },
          [
            _c("v-chart", {
              ref: "vgraph",
              attrs: { autoresize: true, "manual-update": true },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "grid-x align-right" }, [
          _vm.wb
            ? _c(
                "div",
                { staticClass: "filterGraph" },
                _vm._l(_vm.btns, function (item) {
                  return _c(
                    "button",
                    {
                      key: item.idx,
                      staticClass: "btfiltergraph",
                      class: { active: item.active },
                      on: {
                        click: function ($event) {
                          return _vm.changeScale(item)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "grid-x align-bottom infoDw" }, [
        _c("div", { staticClass: "cell shrink text-right" }, [
          _c("span", { staticClass: "date" }, [
            _c("strong", [_vm._v("Fecha:")]),
            _vm._v("\n        " + _vm._s(_vm.spotDate) + "\n      "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "hour" }, [
            _c("strong", [_vm._v("Hora:")]),
            _vm._v("\n        " + _vm._s(_vm.spotTime) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell auto" }),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell shrink btFuente" }, [
      _c("strong", [_vm._v("Fuente:")]),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://dolar.set-icap.com/",
            target: "_blank",
            rel: "noopener",
          },
        },
        [_c("span", [_vm._v("Set-FX")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }