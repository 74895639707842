<template>
  <div
    v-if="live"
    class="apertura-inside"
    data-epica-module-name="Apertura En Vivo"
    data-mrf-recirculation="Apertura En Vivo"
  >
    <div class="row en-vivo">
      <div class="grid-x">
        <div class="icon-live">
          <img
            class=""
            src="https://s.lalr.co/lr/images/icon-live-gif.gif"
            alt="live"
          />
          <span>EN VIVO</span>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="content-videoInside">
      <div class="wrap-videoInside">
        <div
          class="embed-responsive embed-responsive-16by9"
          v-html="live.embedVideo"
        ></div>
        <div class="pd">
          <h2>
            <a class="insideSect" v-html="live.title"></a>
          </h2>
        </div>
      </div>
      <div
        class="feed pd"
        v-if="live.feedTitle && live.feed && live.feed.length"
      >
        <h2 class="title-feed-inside" v-html="live.feedTitle"></h2>
        <div class="wrap-feed">
          <div class="feed-inside">
            <div
              class="list-feed"
              v-for="(item, index) in live.feed"
              :key="index"
            >
              <img
                v-if="item.image"
                :src="item.image.url"
                :alt="item.image.alt"
              />
              <span class="hour" v-html="item.time"></span>
              <span class="date" v-html="item.date"></span>
              <div v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    loadLive: Boolean,
  },
  created: function () {
    this.init();
    if (this.loadLive) {
      this.getLive();
    }
  },
  updated: function () {
    if (this.live) {
      document.dispatchEvent(window.lrLoad);
    }
  },
  computed: {
    ...mapGetters("live", {
      live: "data",
    }),
  },
  methods: {
    ...mapActions("live", {
      init: "init",
      getLive: "getLive",
    }),
  },
};
</script>
