<template>
  <div v-if="post" class="V_Title" :class="post.section.className">
    <a
      class="kicker pd"
      :href="post.isAnalysis ? post.authorUrl : post.postUrl"
      v-html="post.isAnalysis ? post.authorName : post.header"
    ></a>
    <h2 class="text-medium pd">
      <a :href="post.postUrl" v-html="post.title"></a>
    </h2>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("es-co");
export default {
  props: {
    post: Object,
  },
  computed: {
    postDate() {
      return moment(this.post.create).format("dd/MM/yyyy");
    },
  },
};
</script>