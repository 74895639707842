<template>
  <div class="atento-home" v-if="dayNews" data-mrf-recirculation="Atento">
    <div class="atento-title">
      <div class="title">ATENTO</div>
      <div class="real-time">TIEMPO REAL</div>
    </div>
    <div class="sect-atentos">
      <div class="time-line">
        <day-news-feed-item
          v-for="item in dayNews.feed"
          :key="item.id"
          :item="item"
        ></day-news-feed-item>
      </div>
    </div>
    <div class="buttonModule"><a href="/atento" class="btn">MÁS ATENTO</a></div>
  </div>
</template>

<script>
import axios from "axios";
import DayNewsFeedItem from "../day-news-feed-item/DayNewsFeedItem.vue";
export default {
  components: { DayNewsFeedItem },
  created: function () {
    this.$refreshHub.$on("atento-update", this.getDayNews);
    this.getDayNews();
  },
  methods: {
    getDayNews: function () {
      var _self = this;
      axios.get("/api/daynews").then(function (d) {
        _self.dayNews = d.data;
      });
    },
  },
  data: function () {
    return {
      dayNews: null,
    };
  },
};
</script>
