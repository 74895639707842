export const homeMixin = {
    data: function () {
        return {
            qoSelected: 0,
            qaSelected: 0,
            qbSelected: 0,
            quoteGraphKey: 0,
            miniQuoteKey: 0
        };
    },
    methods: {
        changeQb: function (v) {
            this.qbSelected = v;
            this.quoteGraphKey = v;
        },
        changeQo: function (v) {
            this.qoSelected = v;
            this.miniQuoteKey = v;
        }
    }
};