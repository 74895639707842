var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.post
    ? _c(
        "div",
        {
          staticClass: "grid-x H_Img_Title",
          class: _vm.post.section.className,
        },
        [
          _c(
            "div",
            {
              staticClass: "col-1 pd",
              class: { "video-play-icon": _vm.post.format === 4 },
            },
            [
              _c(
                "a",
                { attrs: { href: _vm.post.postUrl } },
                [_c("img-lr", { attrs: { image: _vm.post.principalImage } })],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "auto pr" }, [
            _c("a", {
              staticClass: "kicker",
              attrs: { href: _vm.post.postUrl },
              domProps: { innerHTML: _vm._s(_vm.post.header) },
            }),
            _vm._v(" "),
            _c("h2", { staticClass: "text-medium" }, [
              _c("a", {
                attrs: { href: _vm.post.postUrl },
                domProps: { innerHTML: _vm._s(_vm.post.title) },
              }),
            ]),
            _vm._v(" "),
            _vm.post.isAnalysis
              ? _c("div", { staticClass: "autor" }, [
                  _c("a", {
                    attrs: { href: _vm.post.authorUrl },
                    domProps: { innerHTML: _vm._s(_vm.post.authorName) },
                  }),
                ])
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }