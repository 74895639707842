var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "quote-graph" }, [
    _c(
      "div",
      { staticClass: "graph" },
      [
        _c("v-chart", {
          ref: "vgraph",
          attrs: { autoresize: true, "manual-update": true },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "grid-x align-right" }, [
      _vm.wb
        ? _c(
            "div",
            { staticClass: "filterGraph" },
            _vm._l(_vm.btns, function (item) {
              return _c(
                "button",
                {
                  key: item.idx,
                  staticClass: "btfiltergraph",
                  class: { active: item.active },
                  on: {
                    click: function ($event) {
                      return _vm.changeScale(item)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }