<template>
    <div v-if="live" class="live-70-years mg">
        <div class="embed-responsive embed-responsive-16by9" v-html="live.embedVideo"></div>
        <div class="feed-live">
            <div class="live-signal">
                <div class="special-btn">En Vivo</div>
                <div class="concentric-waves">
                    <div class="point"></div>
                    <div class="spinner-item"></div>
                    <div class="spinner-item spinner-item-2"></div>
                    <div class="spinner-item spinner-item-3"></div>
                </div>
            </div>
            <h2 v-html="live.title"></h2>
            <div class="feeds" v-if="live.feedTitle && live.feed && live.feed.length">
                <div>
                    <div v-for="(item, index) in live.feed" :key="index">
                        <span class="hour" v-html="item.time"></span>
                        <img v-if="item.image" :src="item.image.url" :alt="item.image.alt" />
                        <div v-html="item.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        loadLive: Boolean,
    },
    created: function () {
        this.init();
        if (this.loadLive) {
            this.getLive();
        }
    },
    updated: function () {
        if (this.live) {
            document.dispatchEvent(window.lrLoad);
        }
    },
    computed: {
        ...mapGetters("live", {
            live: "data",
        }),
    },
    methods: {
        ...mapActions("live", {
            init: "init",
            getLive: "getLive",
        }),
    },
};
</script>