<template>
  <div v-if="post" class="I_Img_Title" :class="post.section.className">
    <div class="wrapperMask" :class="{ 'video-play-icon': post.format === 4 }">
      <a :href="post.postUrl">
        <img-lr :image="post.principalImage"></img-lr>
      </a>
      <div class="contentMask">
        <a :href="post.postUrl" class="kicker" v-html="post.header"></a>
        <span class="date-news" v-html="postDate"></span>
        <h2><a :href="post.postUrl" v-html="post.title"></a></h2>
        <div class="autor" v-if="post.isAnalysis">
          <a :href="post.authorUrl" v-html="post.authorName"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ImgLr from "../../components/img-lr/ImgLr.vue";
moment.locale("es-co");
export default {
  props: {
    post: Object,
  },
  components: { ImgLr },
  computed: {
    postDate() {
      return moment(this.post.create).format("dd/MM/yyyy");
    },
  },
};
</script>