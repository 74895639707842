<template>
  <div
    v-if="posts"
    class="Tends pd"
    data-epica-module-name="Tendencias"
    data-mrf-recirculation="Tendencias"
  >
    <div class="containerMostViewed">
      <h3 class="heading-title">TENDENCIAS</h3>
      <ul>
        <li v-for="(post, idx) in posts" :key="post.id">
          <div class="grid-x" :class="post.className">
            <div class="numberTends shrink" v-html="idx + 1"></div>
            <div class="auto">
              <div class="V_Title" :class="post.className">
                <a :href="post.url" class="kicker pd" v-html="post.header"></a>
                <h2 class="text-medium pd">
                  <a :href="post.url" v-html="post.title"></a>
                </h2>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("master", {
      posts: "trends",
    }),
  },
};
</script>
