<template>
    <div class="candidates" v-if="homeData">
        <div class="results red" v-if="homeData.alBulletins">
            <div class="elections-info">
                <h3 class="elections-title">ALCALDÍAS</h3>
                <div class="bulletin">
                    <span>Boletín </span>
                    <span class="number" v-for="(item, bulIdx) in homeData.alBulletins" v-if="alIdx == bulIdx">
                        {{ item.bulletin.bulletinNumber }}
                    </span>
                </div>
            </div>
            <div class="selector">
                <select class="select-box value" v-model="alIdx">
                    <option v-for="(item, bulIdx) in homeData.alBulletins" :value="bulIdx">{{ item.bulletin.location }}
                    </option>
                </select>
            </div>
            <template v-for="(item, bulIdx) in homeData.alBulletins" v-if="alIdx == bulIdx">
                <div class="summary">
                    <div class="tables">
                        <span>
                            Mesas Informadas:
                        </span>
                        <span class="value">
                            {{ item.bulletin.informedTables }}
                        </span>
                    </div>
                    <div class="time" :title="item.bulletin.fullDate">
                        {{ item.bulletin.time }}
                    </div>
                </div>
                <template v-for="(candidate, idx) in item.candidates">
                    <template v-if="idx === 0">
                        <div class="candidate first">
                            <div class="img-wrap">
                                <img height="600" width="600" :src="getImg(candidate.photo)" :alt="candidate.name" />
                            </div>
                            <div class="info">
                                <h4 class="name">{{ candidate.name }}</h4>
                                <div class="bar">
                                    <div class="r" :style="{ width: candidate.barPercent }"></div>
                                </div>
                                <div class="votes">
                                    Votos
                                    <b>{{ candidate.votes }}</b>
                                </div>
                            </div>
                            <div class="percent">
                                {{ candidate.percentage }}
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="candidate">
                            <div class="info">
                                <h4 class="name">{{ candidate.name }}</h4>
                                <div class="bar">
                                    <div class="r" :style="{ width: candidate.barPercent }"></div>
                                </div>
                                <div class="votes">
                                    Votos
                                    <b>{{ candidate.votes }}</b>
                                </div>
                            </div>
                            <div class="percent">
                                {{ candidate.percentage }}
                            </div>
                        </div>
                    </template>
                </template>
                <div class="other">
                    <div>
                        <i class="icon"></i> <span>EN BLANCO: <strong>{{ item.bulletin.blankVotes }}</strong></span>
                    </div>
                    <div>
                        <i class="icon icon-close"></i><span>NO MARCADOS: <strong>{{ item.bulletin.notMarkedVotes }}</strong></span>
                    </div>
                    <div>
                        <i class="icon icon-stop"></i><span>NULOS: <strong>{{ item.bulletin.nullVotes }}</strong></span>
                    </div>
                </div>
            </template>
        </div>
        <hr>
        <div class="results blue" v-if="homeData.goBulletins">
            <div class="elections-info">
                <h3 class="elections-title">GOBERNACIONES</h3>
                <div class="bulletin">
                    <span>Boletín </span>
                    <span class="number" v-for="(item, bulIdx) in homeData.goBulletins" v-if="goIdx == bulIdx">
                        {{ item.bulletin.bulletinNumber }}
                    </span>
                </div>
            </div>
            <div class="selector">
                <select class="select-box value" v-model="goIdx">
                    <option v-for="(item, bulIdx) in homeData.goBulletins" :value="bulIdx">{{ item.bulletin.location }}
                    </option>
                </select>
            </div>
            <template v-for="(item, bulIdx) in homeData.goBulletins" v-if="goIdx == bulIdx">
                <div class="summary">
                    <div class="tables">
                        <span>
                            Mesas Informadas:
                        </span>
                        <span class="value">
                            {{ item.bulletin.informedTables }}
                        </span>
                    </div>
                    <div class="time" :title="item.bulletin.fullDate">
                        {{ item.bulletin.time }}
                    </div>
                </div>
                <template v-for="(candidate, idx) in item.candidates">
                    <template v-if="idx === 0">
                        <div class="candidate first">
                            <div class="img-wrap">
                                <img height="600" width="600" :src="getImg(candidate.photo)" :alt="candidate.name" />
                            </div>
                            <div class="info">
                                <h4 class="name">{{ candidate.name }}</h4>
                                <div class="bar">
                                    <div class="r" :style="{ width: candidate.barPercent }"></div>
                                </div>
                                <div class="votes">
                                    Votos
                                    <b>{{ candidate.votes }}</b>
                                </div>
                            </div>
                            <div class="percent">
                                {{ candidate.percentage }}
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="candidate">
                            <div class="info">
                                <h4 class="name">{{ candidate.name }}</h4>
                                <div class="bar">
                                    <div class="r" :style="{ width: candidate.barPercent }"></div>
                                </div>
                                <div class="votes">
                                    Votos
                                    <b>{{ candidate.votes }}</b>
                                </div>
                            </div>
                            <div class="percent">
                                {{ candidate.percentage }}
                            </div>
                        </div>
                    </template>
                </template>
                <div class="other">
                    <div>
                    <i class="icon"></i><span>EN BLANCO: <strong>{{ item.bulletin.blankVotes }}</strong></span>
                    </div>
                    <div>
                    <i class="icon icon-close"></i><span>NO MARCADOS: <strong>{{ item.bulletin.notMarkedVotes }}</strong></span>
                    </div>
                    <div>
                    <i class="icon icon-stop"></i><span>NULOS: <strong>{{ item.bulletin.nullVotes }}</strong></span>
                    </div>
                </div>
            </template>
        </div>
        <div class="buttonModule view-more"><a href="/elecciones-territoriales-2023" class="value btn">resultados completos</a> </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    created: function () {
        this.$refreshHub.$on("home-elections", this.newInfo);
        this.getData();
    },
    computed: {
        ...mapGetters("elections", {
            homeData: "terr2023_1_home",
        }),
    },
    methods: {
        ...mapActions("elections", {
            getData: "getTerr2023_1_home",
            setInfo: "setTerr2023_1_home",
        }),
        newInfo(data) {
            const _self = this;
            _self.setInfo(data);
            _self.isNew = true;
            setTimeout(() => {
                _self.isNew = false;
            }, 10000);
        },
        getImg(img) {
            return `${img}?w=200`;
        }
    },
    data: function () {
        return {
            isNew: false,
            alIdx: 0,
            goIdx: 0,
        };
    },
};
</script>