import { initMain } from "./vue/controllers/main";
import { header } from "./vue/controllers/header";
import lazyload from "../../lr/js/features/lazy-load";
import TwentyTwenty from "../../lr/js/vue/components/twenty-twenty/TwentyTwenty.vue";
import { homeMixin } from "./vue/mixins/home";
import { ripeTabsMixin } from "./vue/mixins/ripe-tabs";
import VueAgile from "vue-agile";
import Multiselect from "vue-multiselect";

import MiniQuoteGraph from "./vue/components/mini-quote-graph/MiniQuoteGraph.vue";
import DolarSpotGraph from "./vue/components/dolar-spot-graph/DolarSpotGraph.vue";
import QuoteGraph from "./vue/components/quote-graph/QuoteGraph.vue";
import InsideHome from "./vue/components/inside-home/InsideHome.vue";
import MyNews from "./vue/components/my-news/MyNews.vue";
import Trends from "./vue/components/trends/Trends.vue";
import DayNews from "./vue/components/day-news/DayNews.vue";
import LrPlusBenefits from "./vue/components/lr-plus-benefits/LrPlusBenefits.vue";
import HomeElections2023 from "./vue/components/home-elections-2023/HomeElections2023.vue";
import Live70Years from "./vue/components/live-70-years/Live70Years.vue";
import VueTinySlider from "vue-tiny-slider";
import IpcGraph from "./vue/components/ipc-graph/IpcGraph.vue";

lazyload();

initMain(
  [homeMixin, ripeTabsMixin],
  {
    MiniQuoteGraph,
    DolarSpotGraph,
    QuoteGraph,
    TwentyTwenty,
    InsideHome,
    MyNews,
    Trends,
    DayNews,
    LrPlusBenefits,
    HomeElections2023,
    Live70Years,
    Multiselect,
    VueTinySlider,
    IpcGraph,
  },
  [VueAgile]
);
header([
  {
    created() {
      this.userNews();
    },
  },
]);
