<template>
  <div v-if="post" class="grid-x H_Img_Title" :class="post.section.className">
    <div class="col-1 pd" :class="{ 'video-play-icon': post.format === 4 }">
      <a :href="post.postUrl">
        <img-lr :image="post.principalImage"></img-lr>
      </a>
    </div>
    <div class="auto pr">
      <a :href="post.postUrl" class="kicker" v-html="post.header"></a>
      <h2 class="text-medium">
        <a :href="post.postUrl" v-html="post.title"></a>
      </h2>
      <div class="autor" v-if="post.isAnalysis">
        <a :href="post.authorUrl" v-html="post.authorName"></a>
      </div>
    </div>
  </div>
</template>

<script>
import ImgLr from "../../components/img-lr/ImgLr.vue";
export default {
  props: {
    post: Object,
  },
  components: { ImgLr },
};
</script>