<template>
  <div class="wrapInfoDolar" data-mrf-recirculation="Dolar Spot">
    <div v-if="header && header.print">
      <a :href="header.quoteUrl">
        <h3 class="nameIndicator">DOLAR SPOT</h3>
      </a>
      <div class="content-price" v-bind:class="header.print.upDownClass">
        <span class="price" v-html="header.print.closingPrice"></span>
        <i v-bind:class="'icon-' + header.print.upDownClass"></i>
        <div class="grid-x infoDetail">
          <span
            class="varAbs"
            v-bind:class="header.print.upDownClass"
            v-html="header.print.absoluteVariation"
          ></span>
          <span class="varPor" v-html="header.print.percentageVariation"></span>
        </div>
      </div>
      <div class="grid-x pricesIndicator">
        <div>
          <h4 class="labeItemprice">PRECIO DE APERTURA</h4>
          <span class="price" v-html="header.print.openingPrice"></span>
        </div>
        <div>
          <h4 class="labeItemprice">PRECIO PROMEDIO</h4>
          <span class="price" v-html="header.print.avgPrice"></span>
        </div>
        <div>
          <h4 class="labeItemprice">PRECIO MÁXIMO</h4>
          <span class="price" v-html="header.print.maxPrice"></span>
        </div>
        <div>
          <h4 class="labeItemprice">PRECIO MÍNIMO</h4>
          <span class="price" v-html="header.print.minPrice"></span>
        </div>
        <div class="cell noTransacciones">
          <h4 class="labeItemprice">No. DE TRANSACCIONES</h4>
          <span class="price" v-html="header.print.transactionCount"></span>
        </div>
      </div>
    </div>

    <div class="dolar-spot-graph">
      <div class="graph">
        <v-chart ref="vgraph" :autoresize="true" :manual-update="true" />
      </div>
      <div class="grid-x align-right">
        <div class="filterGraph" v-if="wb">
          <button
            class="btfiltergraph"
            v-for="item in btns"
            v-bind:class="{ active: item.active }"
            :key="item.idx"
            @click="changeScale(item)"
          >
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>

    <div class="grid-x align-bottom infoDw">
      <div class="cell shrink text-right">
        <span class="date">
          <strong>Fecha:</strong>
          {{ spotDate }}
        </span>
        <span class="hour">
          <strong>Hora:</strong>
          {{ spotTime }}
        </span>
      </div>
      <div class="cell auto"></div>
      <div class="cell shrink btFuente">
        <strong>Fuente:</strong>
        <a href="https://dolar.set-icap.com/" target="_blank" rel="noopener">
          <span>Set-FX</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ECharts from "vue-echarts";
import VueCompositionAPI from "@vue/composition-api";

import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { TooltipComponent } from "echarts/components";

import axios from "axios";
import { echartformat } from "../../../../../lr/js/functions/general-functions";
import moment from "moment";
Vue.use(VueCompositionAPI);
echarts.use([LineChart, TooltipComponent, CanvasRenderer]);

export default {
  components: { "v-chart": ECharts },
  props: { withButtons: Boolean },
  created: function () {
    this.graphData = {
      color: ["#4872b8"],
      title: {
        show: false,
      },
      textStyle: {
        fontFamily: '"Montserrat", sans-serif',
      },
      grid: {
        left: "80",
        right: "20",
        bottom: "20",
        top: "20",
      },
      legend: {
        show: false,
      },
      xAxis: {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        splitLine: {
          show: true,
          interval: "auto",
        },
        interval: 5,
        inverse: true,
      },
      series: [
        {
          type: "line",
          showSymbol: true,
          label: {
            show: false,
          },
          encode: {
            seriesName: [1],
            x: 0,
            y: 1,
          },
        },
      ],
    };

    this.url = "/api/quote/spot-historic-h";
    this.params = {
      scale: 1,
      withHeader: true,
    };
    this.style = "currency";
    this.decimals = 2;
  },
  mounted: function () {
    this.wb = this.withButtons ? this.withButtons : false;
    this.initGraph();
    this.updateGraphicData(this.wb);
  },
  methods: {
    initGraph: function () {
      const _self = this;
      this.graphData.yAxis = {
        show: true,
        scale: true,
        axisLabel: {
          formatter: function (vv) {
            var val = echartformat(vv, _self.style, _self.decimals);
            return val;
          },
        },
      };
      this.graphData.tooltip = {
        trigger: "axis",
        formatter: function (params) {
          var p = params[0];
          return (
            p.seriesName +
            "<br/>" +
            echartformat(p.value[1], _self.style, _self.decimals) +
            "<br/>" +
            p.name
          );
        },
      };
    },
    updateGraphicData: function (firstTime) {
      var _self = this;
      axios
        .get(this.url, {
          params: this.params,
        })
        .then(function (d) {
          var data = d.data;
          if (_self.wb && firstTime) {
            _self.btns = data.buttonsData;
          }
          if (
            (!_self.wb || _self.btns.filter((q) => q.active)[0].scale == 1) &&
            (!_self.header ||
              _self.header.print.transactionCount !=
                data.spotHeader.print.transactionCount)
          ) {
            _self.toId = setTimeout(() => {
              _self.updateGraphicData(false);
            }, 120000);
          }
          _self.header = data.spotHeader;
          _self.graphData.dataset = {
            source: data.graphData,
          };
          _self.$refs.vgraph.setOption(_self.graphData, true, true);
        });
    },
    changeScale: function (btn) {
      this.btns = this.btns.map((q) => {
        q.active = false;
        return q;
      });
      btn.active = true;
      this.params.scale = btn.scale;
      if (this.toId) {
        clearTimeout(this.toId);
      }
      this.updateGraphicData(false);
    },
  },
  data: function () {
    return {
      wb: false,
      btns: [],
      header: null,
    };
  },
  computed: {
    spotDate: function () {
      return this.header
        ? moment(this.header.transactionDate).format("DD/MM/YYYY")
        : null;
    },
    spotTime: function () {
      return this.header
        ? moment(this.header.transactionDate).format("hh:mm:ss A")
        : null;
    },
  },
};
</script>
