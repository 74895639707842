<template>
  <div v-if="post" class="V_Img_Title" :class="post.section.className">
    <a :href="post.postUrl" :class="{ 'video-play-icon': post.format === 4 }">
      <img-lr :image="post.principalImage"></img-lr>
    </a>
    <a :href="post.postUrl" class="kicker pd" v-html="post.header"></a>
    <h2 class="text-medium pd">
      <a :href="post.postUrl" v-html="post.title"></a>
    </h2>
    <div class="autor" v-if="post.isAnalysis">
      <a :href="post.authorUrl" v-html="post.authorName"></a>
    </div>
  </div>
</template>

<script>
import ImgLr from "../../components/img-lr/ImgLr.vue";
export default {
  components: { ImgLr },
  props: {
    post: Object,
  },
};
</script>