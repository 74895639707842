export const ripeTabsMixin = {
  mounted() {
    if (this.$refs.multiselectGloboCountry) {
        this.selectedGloboCountry = this.$refs.multiselectGloboCountry.options[0];
    }
  },
  watch: {
    selectedGloboCountry(val) {
      this.actualGloboTab = val.key;
    },
  },
  data: function () {
    return {
      actualGloboTab: "",
      selectedGloboCountry: null,
    };
  },
};
