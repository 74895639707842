var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dayNews
    ? _c(
        "div",
        {
          staticClass: "atento-home",
          attrs: { "data-mrf-recirculation": "Atento" },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "sect-atentos" }, [
            _c(
              "div",
              { staticClass: "time-line" },
              _vm._l(_vm.dayNews.feed, function (item) {
                return _c("day-news-feed-item", {
                  key: item.id,
                  attrs: { item: item },
                })
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "atento-title" }, [
      _c("div", { staticClass: "title" }, [_vm._v("ATENTO")]),
      _vm._v(" "),
      _c("div", { staticClass: "real-time" }, [_vm._v("TIEMPO REAL")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buttonModule" }, [
      _c("a", { staticClass: "btn", attrs: { href: "/atento" } }, [
        _vm._v("MÁS ATENTO"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }