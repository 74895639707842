var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.posts
    ? _c(
        "div",
        {
          staticClass: "Tends pd",
          attrs: {
            "data-epica-module-name": "Tendencias",
            "data-mrf-recirculation": "Tendencias",
          },
        },
        [
          _c("div", { staticClass: "containerMostViewed" }, [
            _c("h3", { staticClass: "heading-title" }, [_vm._v("TENDENCIAS")]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.posts, function (post, idx) {
                return _c("li", { key: post.id }, [
                  _c("div", { staticClass: "grid-x", class: post.className }, [
                    _c("div", {
                      staticClass: "numberTends shrink",
                      domProps: { innerHTML: _vm._s(idx + 1) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "auto" }, [
                      _c(
                        "div",
                        { staticClass: "V_Title", class: post.className },
                        [
                          _c("a", {
                            staticClass: "kicker pd",
                            attrs: { href: post.url },
                            domProps: { innerHTML: _vm._s(post.header) },
                          }),
                          _vm._v(" "),
                          _c("h2", { staticClass: "text-medium pd" }, [
                            _c("a", {
                              attrs: { href: post.url },
                              domProps: { innerHTML: _vm._s(post.title) },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }