var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.homeData
    ? _c("div", { staticClass: "candidates" }, [
        _vm.homeData.alBulletins
          ? _c(
              "div",
              { staticClass: "results red" },
              [
                _c("div", { staticClass: "elections-info" }, [
                  _c("h3", { staticClass: "elections-title" }, [
                    _vm._v("ALCALDÍAS"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "bulletin" },
                    [
                      _c("span", [_vm._v("Boletín ")]),
                      _vm._v(" "),
                      _vm._l(_vm.homeData.alBulletins, function (item, bulIdx) {
                        return _vm.alIdx == bulIdx
                          ? _c("span", { staticClass: "number" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.bulletin.bulletinNumber) +
                                  "\n                "
                              ),
                            ])
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "selector" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.alIdx,
                          expression: "alIdx",
                        },
                      ],
                      staticClass: "select-box value",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.alIdx = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.homeData.alBulletins, function (item, bulIdx) {
                      return _c("option", { domProps: { value: bulIdx } }, [
                        _vm._v(
                          _vm._s(item.bulletin.location) + "\n                "
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.homeData.alBulletins, function (item, bulIdx) {
                  return _vm.alIdx == bulIdx
                    ? [
                        _c("div", { staticClass: "summary" }, [
                          _c("div", { staticClass: "tables" }, [
                            _c("span", [
                              _vm._v(
                                "\n                        Mesas Informadas:\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.bulletin.informedTables) +
                                  "\n                    "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "time",
                              attrs: { title: item.bulletin.fullDate },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.bulletin.time) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(item.candidates, function (candidate, idx) {
                          return [
                            idx === 0
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "candidate first" },
                                    [
                                      _c("div", { staticClass: "img-wrap" }, [
                                        _c("img", {
                                          attrs: {
                                            height: "600",
                                            width: "600",
                                            src: _vm.getImg(candidate.photo),
                                            alt: candidate.name,
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "info" }, [
                                        _c("h4", { staticClass: "name" }, [
                                          _vm._v(_vm._s(candidate.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bar" }, [
                                          _c("div", {
                                            staticClass: "r",
                                            style: {
                                              width: candidate.barPercent,
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "votes" }, [
                                          _vm._v(
                                            "\n                                Votos\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(_vm._s(candidate.votes)),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "percent" }, [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(candidate.percentage) +
                                            "\n                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              : [
                                  _c("div", { staticClass: "candidate" }, [
                                    _c("div", { staticClass: "info" }, [
                                      _c("h4", { staticClass: "name" }, [
                                        _vm._v(_vm._s(candidate.name)),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "bar" }, [
                                        _c("div", {
                                          staticClass: "r",
                                          style: {
                                            width: candidate.barPercent,
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "votes" }, [
                                        _vm._v(
                                          "\n                                Votos\n                                "
                                        ),
                                        _c("b", [
                                          _vm._v(_vm._s(candidate.votes)),
                                        ]),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "percent" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(candidate.percentage) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]),
                                ],
                          ]
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "other" }, [
                          _c("div", [
                            _c("i", { staticClass: "icon" }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v("EN BLANCO: "),
                              _c("strong", [
                                _vm._v(_vm._s(item.bulletin.blankVotes)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("i", { staticClass: "icon icon-close" }),
                            _c("span", [
                              _vm._v("NO MARCADOS: "),
                              _c("strong", [
                                _vm._v(_vm._s(item.bulletin.notMarkedVotes)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("i", { staticClass: "icon icon-stop" }),
                            _c("span", [
                              _vm._v("NULOS: "),
                              _c("strong", [
                                _vm._v(_vm._s(item.bulletin.nullVotes)),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    : _vm._e()
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.homeData.goBulletins
          ? _c(
              "div",
              { staticClass: "results blue" },
              [
                _c("div", { staticClass: "elections-info" }, [
                  _c("h3", { staticClass: "elections-title" }, [
                    _vm._v("GOBERNACIONES"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "bulletin" },
                    [
                      _c("span", [_vm._v("Boletín ")]),
                      _vm._v(" "),
                      _vm._l(_vm.homeData.goBulletins, function (item, bulIdx) {
                        return _vm.goIdx == bulIdx
                          ? _c("span", { staticClass: "number" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.bulletin.bulletinNumber) +
                                  "\n                "
                              ),
                            ])
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "selector" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.goIdx,
                          expression: "goIdx",
                        },
                      ],
                      staticClass: "select-box value",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.goIdx = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.homeData.goBulletins, function (item, bulIdx) {
                      return _c("option", { domProps: { value: bulIdx } }, [
                        _vm._v(
                          _vm._s(item.bulletin.location) + "\n                "
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.homeData.goBulletins, function (item, bulIdx) {
                  return _vm.goIdx == bulIdx
                    ? [
                        _c("div", { staticClass: "summary" }, [
                          _c("div", { staticClass: "tables" }, [
                            _c("span", [
                              _vm._v(
                                "\n                        Mesas Informadas:\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.bulletin.informedTables) +
                                  "\n                    "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "time",
                              attrs: { title: item.bulletin.fullDate },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.bulletin.time) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(item.candidates, function (candidate, idx) {
                          return [
                            idx === 0
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "candidate first" },
                                    [
                                      _c("div", { staticClass: "img-wrap" }, [
                                        _c("img", {
                                          attrs: {
                                            height: "600",
                                            width: "600",
                                            src: _vm.getImg(candidate.photo),
                                            alt: candidate.name,
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "info" }, [
                                        _c("h4", { staticClass: "name" }, [
                                          _vm._v(_vm._s(candidate.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bar" }, [
                                          _c("div", {
                                            staticClass: "r",
                                            style: {
                                              width: candidate.barPercent,
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "votes" }, [
                                          _vm._v(
                                            "\n                                Votos\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(_vm._s(candidate.votes)),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "percent" }, [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(candidate.percentage) +
                                            "\n                        "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              : [
                                  _c("div", { staticClass: "candidate" }, [
                                    _c("div", { staticClass: "info" }, [
                                      _c("h4", { staticClass: "name" }, [
                                        _vm._v(_vm._s(candidate.name)),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "bar" }, [
                                        _c("div", {
                                          staticClass: "r",
                                          style: {
                                            width: candidate.barPercent,
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "votes" }, [
                                        _vm._v(
                                          "\n                                Votos\n                                "
                                        ),
                                        _c("b", [
                                          _vm._v(_vm._s(candidate.votes)),
                                        ]),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "percent" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(candidate.percentage) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]),
                                ],
                          ]
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "other" }, [
                          _c("div", [
                            _c("i", { staticClass: "icon" }),
                            _c("span", [
                              _vm._v("EN BLANCO: "),
                              _c("strong", [
                                _vm._v(_vm._s(item.bulletin.blankVotes)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("i", { staticClass: "icon icon-close" }),
                            _c("span", [
                              _vm._v("NO MARCADOS: "),
                              _c("strong", [
                                _vm._v(_vm._s(item.bulletin.notMarkedVotes)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("i", { staticClass: "icon icon-stop" }),
                            _c("span", [
                              _vm._v("NULOS: "),
                              _c("strong", [
                                _vm._v(_vm._s(item.bulletin.nullVotes)),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    : _vm._e()
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(0),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buttonModule view-more" }, [
      _c(
        "a",
        {
          staticClass: "value btn",
          attrs: { href: "/elecciones-territoriales-2023" },
        },
        [_vm._v("resultados completos")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }